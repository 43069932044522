import React, { useState } from "react";
import styles from "./Newsletter.module.sass";

/*

 const SimpleForm = () => <MailchimpSubscribe 
                            url={url} 
                            submitText={language==='de' ? 'anmelden' : 's\'inscrire' } />

                            */

const Index = ({ language }) => {
  const [firstname, setFirstname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <div className={styles.newsletter}>
      <div>
        <h3>
          {language === "de" ? "NEWSLETTER ANMELDEN" : "INSCRIPTION NEWSLETTER"}
        </h3>

        <div>
          <form
            method="post"
            action="https://dm-mailinglist.com/subscribe"
            data-directmail-use-ajax="1"
            data-form-id="2929d5a7"
            className="directmail-subscribe-form"
            accept-charset="UTF-8"
          >
            <input type="hidden" name="form_id" value="2929d5a7" />
            <input type="email" name="email" style={{ display: "none" }} />
            <div className="directmail-main-table-container">
              <table className="directmail-main-table">
                <tr>
                  <td className="directmail-form-description" colspan="1"></td>
                </tr>
                <tr>
                  <td>
                    <label
                      className="directmail-form-label directmail-default-no-label"
                      for="directmail-2929d5a7-first_name"
                    >
                      Vorname
                    </label>
                    <input
                      type="text"
                      id="directmail-2929d5a7-first_name"
                      name="first_name"
                      value={firstname}
                      className=""
                      onChange={(event) =>
                        setFirstname(event.currentTarget.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      className="directmail-form-label directmail-default-no-label"
                      for="directmail-2929d5a7-last_name"
                    >
                      Nachname
                    </label>
                    <input
                      type="text"
                      id="directmail-2929d5a7-last_name"
                      name="last_name"
                      value={name}
                      className=""
                      onChange={(event) => setName(event.currentTarget.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      className="directmail-form-label directmail-default-no-label"
                      for="directmail-2929d5a7-subscriber_email"
                    >
                      E-Mail*
                    </label>
                    <input
                      type="email"
                      id="directmail-2929d5a7-subscriber_email"
                      name="subscriber_email"
                      value={email}
                      className="directmail-required-field"
                      required
                      onChange={(event) => setEmail(event.currentTarget.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="submit"
                      value={language === "de" ? "anmelden" : "s'inscrire"}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </form>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <h3>
          {language === "de"
            ? "NEWSLETTER ABMELDEN"
            : "DÉSINSCRIPTION NEWSLETTER"}
        </h3>
        <p>
          <a href="https://impulsneuemusik.us8.list-manage.com/unsubscribe?u=f04c5d1d56d1e11efc2ef4bcd&id=193a426845">
            {language === "de" ? "abmelden" : "se désinscrire"}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Index;
